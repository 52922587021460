import { ThemeProvider, StyleReset, Text, Container, Div, Row, Col } from "atomize"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import SEO from "../components/seo"
import "../settings/index.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStore, faUserFriends } from "@fortawesome/free-solid-svg-icons"
import { motion } from "framer-motion"
import HeadingText from "../components/HeadingText"
let config = require("../settings/config")

const theme = config.theme
const url = config.url

const UserType = props => {
    return (
        <Col size={{ xs: "12", md: "6" }}>
            <Link to={props.link} title={props.text}>
                <motion.div whileTap={{ scale: 0.95 }}>
                    <Div p="3rem" m={{ y: { xs: "0.5rem", lg: "0" } }} shadow="top-3" rounded="xxl">
                        {props.icon}
                        <Text
                            p={{ t: { xs: "0.25rem", md: "0.75rem" } }}
                            textSize={{ xs: "subheader", md: "title" }}
                            textColor="primary"
                        >
                            {props.text}
                        </Text>
                    </Div>
                </motion.div>
            </Link>
        </Col>
    )
}

const OrderNow = () => (
    <ThemeProvider theme={theme}>
        <StyleReset />

        <Header />
        <SEO
            title="Hai dubbi? Consulta la pagina Domande Frequenti"
            description="In questa pagina trovi le risposte alle Domande Frequenti dei nostri clienti e ristoranti partner. Scopri come acquistare cibo da asporto con la nostra app!"
            pathname="faq"
        />
        <main style={{ position: "relative", overflow: "hidden" }}>
            <section>
                <Div
                    bg="white"
                    pos="relative"
                    zIndex="1"
                    p={{ t: { xs: "0", md: "1rem", lg: "6rem", xl: "7rem" } }}
                    m={{ y: "1rem" }}
                >
                    <Container>
                        <Text tag="h1" d="none">
                            Ecco le risposte alle domande frequenti
                        </Text>
                        <HeadingText textAlign="center" p={{ y: "2rem" }}>
                            Domande frequenti
                        </HeadingText>
                        <Row textAlign="center">
                            <UserType
                                link={url.faqUser}
                                icon={<FontAwesomeIcon icon={faUserFriends} size="5x" color={theme.colors.primary} />}
                                text="Sono un utente"
                            />

                            <UserType
                                link={url.faqSupp}
                                icon={<FontAwesomeIcon icon={faStore} size="5x" color={theme.colors.primary} />}
                                text="Ho un'attività"
                            />
                        </Row>
                    </Container>
                </Div>
            </section>
        </main>
        <Footer />
    </ThemeProvider>
)

export default OrderNow
